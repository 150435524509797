@use 'src/styles/app' as a;

.chapters {
  gap: var(--spacer-32);
  display: grid;
  grid-template-columns: repeat(3, auto);

  @include a.breakpoint(md) {
    grid-template-columns: repeat(2, auto);
  }

  &__item {
    color: var(--primary);
    display: flex;
    padding: 24px 40px;
    position: relative;
    box-shadow: inset 0 0 0 var(--border-width-5) var(--primary);
    align-items: center;
    border-radius: var(--border-radius-24);
    flex-direction: column;
    text-decoration: none;
    transition-property: all;

    @include a.breakpoint(xl) {
      padding: 20px 32px;
    }
    @include a.breakpoint(md) {
      padding: 20px 24px;
    }
    @include a.breakpoint(xs) {
      padding: 20px;
    }

    &:hover {
      color: a.$gray-100;
      box-shadow: none;
      background: var(--primary);
    }
  }

  &__ico {
    width: 100px;
    height: 100px;
    background: var(--primary);
    margin-bottom: var(--spacer-10);
    border-radius: var(--border-radius-16);

    @include a.breakpoint(md) {
      width: 80px;
      height: 80px;
    }
  }

  &__name {
    color: currentColor;
    margin: 0;
  }
}
