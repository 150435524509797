@use 'src/styles/app' as a;

.tabs-section {
  margin: 0 auto var(--spacer-200);
  padding: 0 var(--gutter);

  &__head {
    margin: 0 auto var(--spacer-60);
    max-width: var(--container-lg);
  }

  &__menu {
    margin-top: var(--spacer-12);
  }

  &__body {
    display: flex;
    flex-direction: column;

    @include a.breakpoint(xs) {
      margin: 0 -16px;
    }
  }

  &__item {
    order: 2;
    display: none;

    > :last-child {
      margin-bottom: 0;
    }

    > :first-child {
      margin-bottom: 0;
    }

    &_active {
      order: 1;
      display: block;
    }
  }
}
