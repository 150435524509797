@use 'sass:map';
@use '../app' as a;

.btn {
  $b: &;

  --primary: #{a.$blue-100};
  --primary-dark: #{a.$blue-200};

  cursor: pointer;
  outline: none;
  display: inline-flex;
  user-select: none;
  align-items: center;
  justify-content: center;

  color: a.$light-color;
  padding: 12px 32px;
  position: relative;
  min-height: 52px;
  background: var(--primary);
  box-shadow: 0 0 0 var(--primary);
  margin-top: var(--spacer-40);
  line-height: 1;
  border-radius: 8px;
  vertical-align: top;
  text-decoration: none;
  transition-property: color, border, box-shadow, background;

  @include a.breakpoint(lg) {
    padding: 12px 24px;
    min-height: 46px;
  }
  @include a.breakpoint(md) {
    padding: 12px 20px;
    min-height: 44px;
  }

  &::before {
    width: 1em;
    height: 1em;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    background: {
      size: auto 100%;
      position: 50% 50%;
    }
  }

  &[href*='mailto'] {
    &::before {
      content: '';
      background-image: url('/src/uploads/assets/icons/email.png');
    }
  }

  &[href*='vimeo'] {
    &::before {
      content: '';
      background-image: url('/src/uploads/assets/icons/film.png');
    }
  }

  &[href*='github'] {
    &::before {
      content: '';
      background-image: url('/src/uploads/assets/icons/tools.png');
    }
  }

  &[href*='linkedin'] {
    &::before {
      content: '';
      background-image: url('/src/uploads/assets/icons/salute.png');
    }
  }

  &[href*='instagram'] {
    &::before {
      content: '';
      background-image: url('/src/uploads/assets/icons/photo.png');
    }
  }

  &:focus {
    color: a.$light-color;
    background: var(--primary-dark);
    box-shadow: 0 0 0 2px var(--primary);
    border-color: var(--primary-dark);
  }

  @include a.hover {
    color: a.$light-color;
    background: var(--primary-dark);
    box-shadow: 0 0 0 var(--primary);
    border-color: var(--primary-dark);
  }

  &:active {
    color: a.$gray-500;
    background: var(--primary);
    box-shadow: 0 0 0 var(--primary);
    border-color: var(--primary);
  }

  &:disabled {
    color: a.$light-color;
    background: a.$gray-200;
    border-color: a.$gray-200;
  }

  &:first-child {
    margin-top: 0;
  }

  &_dark {
    --primary: #{a.$gray};
    --primary-dark: #{a.$gray-dark};

    &:active {
      color: a.$yellow-100;
    }
  }

  &_light {
    color: a.$gray-400;
    background: a.$gray-100;
  }

  &_outline {
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 10px 32px;
    font-size: 22px;
    background: transparent;
    line-height: 1.3;

    @include a.breakpoint(lg) {
      padding: 10px 24px;
      font-size: 14px;
      line-height: 1.4;
    }
    @include a.breakpoint(md) {
      padding: 10px 20px;
      font-size: 12px;
      line-height: 1.3;
    }
  }
}
