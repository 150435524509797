@use 'sass:math' as *;
@use 'src/styles/app' as a;

.footer {
  color: a.$dark-color;
  padding: 0 var(--gutter);

  :where(a) {
    color: a.$gray-400;
    font-weight: 400;
    text-decoration-color: transparent;

    @include a.hover {
      text-decoration-color: a.$primary;
    }
  }

  &__inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: var(--container-lg);
    border-top: solid 1px a.$gray-400;
    align-items: flex-start;
    padding-block: 32px 40px;

    @include a.breakpoint(lg) {
      padding-block: 24px 32px;
    }
    @include a.breakpoint(sm) {
      gap: 0 73px;
    }
    @include a.breakpoint(xs) {
      gap: 0;
    }
  }

  &__logo {
    color: a.$dark-color;
    font-size: 25px;
  }

  &__menu {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    @include a.breakpoint(md) {
      width: 100%;
      margin-top: 24px;
      justify-content: space-between;
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    margin-top: 4px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__info {
    font-weight: 700;

    @include a.breakpoint(sm) {
      width: 100%;
      margin-top: 24px;
    }
  }

  &__call {
    margin-top: 4px;
  }
}
