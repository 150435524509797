@use 'src/styles/app' as a;

.header {
  $b: &;

  width: 100%;
  color: a.$dark-color;
  margin: 40px auto 80px;
  z-index: 100;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: var(--container-lg);
  align-items: center;
  padding-inline: var(--gutter);
  justify-content: space-between;

  @include a.breakpoint(lg) {
    margin: 32px auto 60px;
  }
  @include a.breakpoint(xs) {
    margin-bottom: 40px;
  }

  &__logo {
    z-index: 20;
    position: relative;
    font-size: 25px;
  }

  &__switch {
    width: 50px;
    height: 36px;
    z-index: 10;
    display: none;
    position: relative;
    align-items: flex-end;
    flex-direction: column;
    transition-property: color, opacity, transform;

    @include a.breakpoint(md) {
      display: flex;
    }

    span {
      transform: scaleX(0.8);
      transition: all a.$time a.$ease;
      transform-origin: 100% 50%;
    }

    span,
    &::after,
    &::before {
      top: 50%;
      right: 0;
      width: 100%;
      height: 4px;
      content: '';
      position: absolute;
      background: currentColor;
      border-radius: 4px;
      transition-property: transform;
    }

    &::before {
      transform: translateY(-14px);
    }

    &::after {
      transform: translateY(14px);
    }

    @include a.hover {
      color: a.$primary;

      span {
        transform: none;
      }
    }
  }

  &_menu-open {
    z-index: 1500;

    #{$b}__switch {
      transform: translateX(7px);

      &::before {
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        transform: translateY(-50%) rotate(-45deg);
      }

      span {
        opacity: 0;
        transform: scaleX(0.6);
      }
    }
  }
}
