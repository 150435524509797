@use 'src/styles/app' as a;
@use 'post';
@use '../post-meta/post-meta';

.posts {
  $b: &;

  text-align: center;

  &__head {
    text-align: left;
  }

  &__body {
    gap: var(--spacer-32);
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }

  &__column {
    $e: &;

    gap: var(--spacer-32);
    flex: 1;
    display: flex;
    flex-direction: column;

    @at-root [data-columns='1'] {
      :nth-child(5n-3) {
        --primary: #{a.$green};
        --primary-dark: #{a.$green-dark};
      }

      :nth-child(5n-2) {
        --primary: #{a.$yellow};
        --primary-dark: #{a.$yellow-dark};
      }

      :nth-child(5n-1) {
        --primary: #{a.$blue};
        --primary-dark: #{a.$blue-dark};
      }

      :nth-child(5n) {
        --primary: #{a.$red};
        --primary-dark: #{a.$red-dark};
      }
    }

    @at-root [data-columns='2'] {
      #{$e}:nth-child(1) {
        :nth-child(3n-1) {
          --primary: #{a.$green};
          --primary-dark: #{a.$green-dark};
        }

        :nth-child(3n) {
          --primary: #{a.$yellow};
          --primary-dark: #{a.$yellow-dark};
        }
      }

      #{$e}:nth-child(2) {
        :nth-child(3n-2) {
          --primary: #{a.$blue};
          --primary-dark: #{a.$blue-dark};
        }

        :nth-child(3n-1) {
          --primary: #{a.$red};
          --primary-dark: #{a.$red-dark};
        }
      }
    }

    @at-root [data-columns='3'] {
      #{$e}:nth-child(1) {
        :nth-child(3n-1) {
          --primary: #{a.$green};
          --primary-dark: #{a.$green-dark};
        }

        :nth-child(3n) {
          --primary: #{a.$yellow};
          --primary-dark: #{a.$yellow-dark};
        }
      }

      #{$e}:nth-child(2) {
        :nth-child(3n-2) {
          --primary: #{a.$blue};
          --primary-dark: #{a.$blue-dark};
        }

        :nth-child(3n-1) {
          --primary: #{a.$red};
          --primary-dark: #{a.$red-dark};
        }
      }

      #{$e}:nth-child(3) {
        :nth-child(3n-2) {
          --primary: #{a.$green};
          --primary-dark: #{a.$green-dark};
        }

        :nth-child(3n-1) {
          --primary: #{a.$yellow};
          --primary-dark: #{a.$yellow-dark};
        }

        :nth-child(3n) {
          --primary: #{a.$blue};
          --primary-dark: #{a.$blue-dark};
        }
      }
    }
  }
}
