@use 'sass:math' as *;
@use '../app' as a;

:root {
  // LAYOUT
  @include a.varify-simple-map(a.$gutter-map) using ($value) {
    --gutter: #{$value};
  }
  @include a.varify-strong-map(a.$spacers-map) using ($key, $value) {
    --spacer-#{$key}: #{$value};
  }
  @include a.varify-strong-map(a.$containers-map) using ($key, $value) {
    --container-#{$key}: #{$value};
  }

  // DESIGN
  @include a.varify-strong-map(a.$padding-map) using ($key, $value) {
    --padding-#{$key}: #{$value};
  }
  @include a.varify-strong-map(a.$border-width-map) using ($key, $value) {
    --border-width-#{$key}: #{$value};
  }
  @include a.varify-strong-map(a.$border-radius-map) using ($key, $value) {
    --border-radius-#{$key}: #{$value};
  }

  // TYPOGRAPHY
  @include a.varify-strong-map(a.$font-sizes) using ($key, $value) {
    --font-size-#{$key}: #{$value};
  }
  @include a.varify-simple-map(a.$line-heights) using ($value) {
    --line-height: #{$value};
  }
}

::after,
::before,
:where([class]) {
  transition: {
    duration: 0.3s;
    property: auto;
    timing-function: a.$ease;
  }
}
