@use '../app' as a;

:where(a) {
  color: var(--color, currentColor);
  transition: {
    duration: a.$time;
    property: color, text-decoration;
    timing-function: a.$ease;
  }
  text-decoration: {
    line: underline;
    color: transparent;
    thickness: 1px;
  }
  text-underline-offset: 2px;

  @include a.hover {
    text-decoration: {
      color: a.$primary;
      thickness: 2px;
    }
  }

  &:active {
    text-decoration: {
      color: a.$primary-dark;
    }
  }
}

:where(a:not([class])) {
  text-decoration: {
    color: currentColor;
  }

  @include a.hover {
    color: a.$primary-dark;
  }
}

:where(p, ul, ol, pre, img, table) {
  margin-top: var(--spacer-12);
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }
}

pre {
  color: a.$light-color;
  margin: var(--spacer-40) auto;
  padding: var(--padding-48);
  display: flex;
  background: a.$gray-500;
  border-radius: var(--border-radius-24);

  code {
    border: 0;
    padding: 0;
    background: none;
  }
}

code {
  border: 1px solid a.$gray-200;
  padding: 2px 16px;
  display: inline-block;
  font-size: 16px;
  background: a.$gray-100;
  line-height: 1.7;
  font-family: a.$mono-font-family;
  border-radius: 6px;
  letter-spacing: normal;

  @include a.breakpoint(xs) {
    font-size: 12px;
  }
}

:where(ul:not([class]) > li) {
  position: relative;
  margin-top: a.em(12);
  padding-left: a.em(26);

  &::before {
    top: a.em(11);
    left: a.em(10);
    width: a.em(6);
    height: a.em(6);
    content: '';
    position: absolute;
    background: currentColor;
    border-radius: 50%;

    @include a.breakpoint(lg) {
      top: a.em(9);
    }
  }

  &:where(:first-child) {
    margin-top: 0;
  }
}

:where(i, em) {
  color: var(--accent, var(--color, #{a.$dark-color}));
  font-style: italic;
}

:where(b, strong) {
  color: var(--accent, var(--color, #{a.$dark-color}));
  font-weight: 700;
}
