@use 'src/styles/app' as a;

.crumbs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  margin-top: 30px;
  margin-left: -4px;

  @include a.breakpoint(xxl) {
    margin-top: 20px;
  }

  &__item {
    color: a.$gray-400;
    position: relative;
    margin-left: 4px;
    padding-right: 7px;

    &::after {
      top: 0;
      right: 0;
      color: a.$dark-color;
      content: '/';
      display: block;
      position: absolute;
    }

    &:last-child {
      overflow: hidden;
      white-space: nowrap;
      padding-right: 0;
      text-overflow: ellipsis;

      &::after {
        content: none;
      }
    }
  }

  &__link {
    color: a.$dark-color;
    text-decoration-color: transparent;
  }
}
