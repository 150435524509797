@use '../app' as a;

.heading {
  $b: &;

  gap: 16px;
  display: flex;
  position: relative;
  padding-bottom: 21px;

  @include a.breakpoint(xl) {
    gap: 10px;
  }
  @include a.breakpoint(lg) {
    gap: 6px;
    padding-bottom: 15px;
  }
  @include a.breakpoint(md) {
    padding-bottom: 13px;
  }

  &::after {
    left: 0;
    width: calc(calc(var(--container-lg) - var(--spacer-32)) / 2);
    bottom: 0;
    height: 5px;
    content: '';
    position: absolute;
    background: var(--color, #{a.$yellow});
    border-radius: 4px;
    transform-origin: 0 0;
    transition: {
      delay: calc(var(--index) * 0.01s + 0.2s);
      property: opacity, transform;
    }

    @include a.breakpoint(lg) {
      height: 3px;
    }
    @include a.breakpoint(xs) {
      width: calc(50% - calc(var(--spacer-32) / 2));
    }
  }

  &_hot {
    &::before {
      width: a.em(82, 90);
      height: a.em(75, 90);
      content: '';
      display: block;
      margin-top: a.em(16, 90);
      background: {
        size: contain;
        image: url('/src/uploads/assets/icons/hot.svg');
      }
    }
  }

  &[data-scroll],
  [data-scroll] &:not([data-scroll]) {
    &::after {
      opacity: 0;
      transform: scaleX(0.15);
    }
  }

  &[data-scroll='in'],
  [data-scroll='in'] &:not([data-scroll]) {
    &::after {
      opacity: 1;
      transform: none;
    }
  }
}
