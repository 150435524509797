@use 'src/styles/app' as a;

.cookies {
  gap: 20px;
  left: 50%;
  width: 100%;
  bottom: var(--spacer-32);
  opacity: 0;
  padding: 16px;
  display: flex;
  z-index: 1000;
  position: fixed;
  max-width: 580px;
  transform: translateX(-50%) scale(0.8);
  visibility: hidden;
  background: a.$yellow;
  perspective: 1px;
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  transform-origin: 50% 100%;
  transition-property: opacity, transform, visibility;

  @include a.breakpoint(lg) {
    max-width: 464px;
  }
  @include a.breakpoint(xs) {
    gap: 16px;
    bottom: 16px;
  }

  &::before {
    width: 52px;
    height: 52px;
    content: '';
    flex-shrink: 0;
    background: {
      size: contain;
      image: url('/src/uploads/assets/icons/cookies.png');
    }

    @include a.breakpoint(lg) {
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    color: a.$light-color;
    font-size: 16px;
    line-height: 1.4;

    @include a.breakpoint(lg) {
      font-size: 12px;
    }
    @include a.breakpoint(xs) {
      font-size: 10px;
    }

    a {
      @include a.hover {
        color: a.$light-color;

        text-decoration: {
          color: a.$light-color;
        }
      }
    }
  }

  &__btn {
    width: 96px;
    color: a.$yellow;
    margin: 0;

    @include a.breakpoint(lg) {
      width: 84px;
    }
    @include a.breakpoint(xs) {
      width: 74px;
    }
  }

  &_show {
    opacity: 1;
    transform: translateX(-50%);
    visibility: visible;
  }
}
