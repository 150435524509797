@use 'src/styles/app' as a;

.logo {
  $b: &;

  border: none;
  display: flex;
  font-size: 48px;
  line-height: 1;
  align-items: flex-start;
  font-family: ico, serif;
  flex-direction: column;
  text-decoration: none;
  transition-property: color;

  &::before {
    content: a.$ico-logo;
  }

  @include a.hover {
    color: a.$primary;
  }
}
