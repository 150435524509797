@use 'sass:map';
@use 'src/styles/app' as a;

.feature {
  $b: &;

  text-align: center;

  &::after {
    width: 120px;
    height: 2px;
    margin: 2px auto 0;
    content: '';
    display: block;
    background: a.$primary;
    border-radius: 4px;

    @include a.breakpoint(xl) {
      width: 80px;
    }
  }

  &__ico {
    width: 80px;
    margin: 0 auto var(--spacer-10);
    display: flex;

    @include a.breakpoint(lg) {
      width: 60px;
    }
    @include a.breakpoint(md) {
      width: 48px;
    }
  }
}
