@use 'src/styles/app' as a;

.submenu {
  $b: &;

  width: 240px;
  padding: 16px;

  @include a.breakpoint(md) {
    width: 100%;
    display: block;
    padding: 8px 30px 0;
  }

  &__link {
    display: flex;
    margin-top: 10px;
    text-decoration: none;

    @include a.breakpoint(md) {
      color: a.$gray-400;
      font-size: 18px;
      margin-top: 4px;
      line-height: 1.6;
    }

    &:hover,
    &_active {
      color: a.$blue;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
