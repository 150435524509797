@use 'src/styles/app' as a;
@use '../feedback/feedback';

.form-section {
  $b: &;

  margin: 0 auto var(--spacer-200);
  padding: 0 var(--gutter);

  &__inner {
    margin: 0 auto;
    display: flex;
    max-width: var(--container-lg);
    flex-direction: column;

    &::after {
      order: 1;
      width: a.col(6, a.$gap);
      height: 5px;
      content: '';
      display: block;
      margin-top: var(--spacer-16);
      background: var(--color, #{a.$blue});
      border-radius: 4px;
      transform-origin: 0 0;
      transition: {
        delay: calc(var(--index) * 0.01s);
        property: opacity, transform;
      }

      @include a.breakpoint(lg) {
        width: a.col(6, a.$gap-lg);
        height: 3px;
      }
      @include a.breakpoint(md) {
        width: a.col(6, a.$gap-md);
      }
      @include a.breakpoint(xs) {
        width: 50%;
      }
    }

    &[data-scroll] {
      &::after {
        opacity: 0;
        transform: scaleX(0.15);
      }
    }
  }

  &__body {
    gap: var(--spacer-32);
    order: 2;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacer-60);

    @include a.breakpoint(lg) {
      gap: 0;
    }
  }

  &__info {
    width: a.col(4, a.$gap);

    @include a.breakpoint(lg) {
      gap: var(--spacer-32);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__cell {
    @include a.breakpoint(lg) {
      width: a.col(6, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: a.col(6, a.$gap-md);
    }
    @include a.breakpoint(xs) {
      width: 100%;
    }

    &:last-child {
      margin-top: var(--spacer-40);

      @include a.breakpoint(lg) {
        margin-top: 0;
      }
    }
  }

  &__form {
    width: a.col(8, a.$gap);

    @include a.breakpoint(lg) {
      width: 100%;
      margin-top: 32px;
    }
  }

  &_main {
    #{$b}__head {
      order: 0;
      display: flex;
      align-items: center;

      @include a.breakpoint(xs) {
        align-items: flex-start;
        flex-direction: column;
      }

      &::before {
        order: 1;
        width: 114px;
        height: 114px;
        content: '';
        display: block;
        flex-shrink: 0;
        margin-left: var(--spacer-16);
        background: {
          size: contain;
          image: url('/src/uploads/common/emoji-v.png');
        }

        @include a.breakpoint(lg) {
          width: 84px;
          height: 84px;
        }
        @include a.breakpoint(md) {
          width: 68px;
          height: 68px;
        }
        @include a.breakpoint(xs) {
          order: 0;
          margin-left: 0;
          margin-bottom: 6px;
        }
      }
    }

    #{$b}__title {
      max-width: 908px;

      @include a.breakpoint(lg) {
        max-width: 668px;
      }
      @include a.breakpoint(sm) {
        max-width: 342px;
      }
    }
  }
}
