@use 'src/styles/app' as a;

.about-mill {
  gap: var(--spacer-32);
  color: a.$light-color;
  display: flex;
  flex-wrap: wrap;

  @include a.breakpoint(sm) {
    gap: 32px;
  }

  h2 {
    color: a.$red;
  }

  h3 {
    color: currentColor;
  }

  mark {
    color: currentColor;
    border: 1px solid currentColor;
    display: inline-block;
    padding: 12px;
    font-size: 15px;
    background: none;
    line-height: 1;
    border-radius: 10px;

    @include a.breakpoint(lg) {
      font-size: 12px;
    }
    @include a.breakpoint(md) {
      padding: 10px;
      font-size: 10px;
    }
  }

  hgroup {
    &::after {
      height: 5px;
      content: '';
      display: block;
      margin-top: var(--spacer-16);
      background: a.$red;
      border-radius: 4px;
      transform-origin: 0 0;
      transition: {
        delay: calc(var(--index) * 0.01s);
        property: opacity, transform;
      }

      @include a.breakpoint(lg) {
        height: 3px;
      }
      @include a.breakpoint(sm) {
        width: 50%;
      }
    }
  }

  &__col {
    width: a.col(6, a.$gap);
    display: flex;
    flex-direction: column;

    @include a.breakpoint(lg) {
      width: a.col(6, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: a.col(6, a.$gap-md);
    }
    @include a.breakpoint(sm) {
      width: 100%;
    }

    .btn {
      margin-left: auto;
    }

    &[data-scroll] {
      hgroup::after {
        opacity: 0;
        transform: scaleX(0.15);
        transition-delay: 0s;
      }
    }
  }

  &_dark {
    color: a.$dark-color;

    h3 {
      color: a.$gray-500;
    }
  }
}
