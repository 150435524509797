@use 'sass:color';

// GLOBAL COLORS
$red-100: #e88375;
$red-200: #d66759;
$red-300: #a00028;

$blue-100: #9f9ffe;
$blue-200: #7878f0;

$pink-100: #c08ad2;
$pink-200: #a96cbe;

$gray-100: #f7fafc;
$gray-200: #cdc5d0;
$gray-300: #b7b1c7;
$gray-400: #626871;
$gray-500: #303745;
$gray-600: #262c37;

$green-100: #72c292;
$green-200: #59a376;

$yellow-100: #d6ba59;
$yellow-200: #d0b041;

$dark-color: $gray-500;
$light-color: $gray-100;

// COLOR STATES
$red: $red-100;
$red-dark: $red-200;

$blue: $blue-100;
$blue-dark: $blue-200;

$pink: $pink-100;
$pink-dark: $pink-200;

$gray: $gray-500;
$gray-dark: $gray-600;

$green: $green-100;
$green-dark: $green-200;

$yellow: $yellow-100;
$yellow-dark: $yellow-200;

$color-map: (
  'red': (
    base: $red,
    dark: $red-dark,
  ),
  'blue': (
    base: $blue,
    dark: $blue-dark,
  ),
  'pink': (
    base: $pink,
    dark: $pink-dark,
  ),
  'gray': (
    base: $gray,
    dark: $gray-dark,
  ),
  'green': (
    base: $green,
    dark: $green-dark,
  ),
  'yellow': (
    base: $yellow,
    dark: $yellow-dark,
  ),
);

$color-fb: #1877f2;
$color-tw: #1da1f2;
$color-in: #0a66c2;
