@use 'variables/colors' as c;

// colors
@each $name, $props in c.$color-map {
  .#{$name} {
    @each $key, $value in $props {
      @if ($key == base) {
        --primary: #{$value};
      } @else {
        --primary-#{$key}: #{$value};
      }
    }
  }
}

// text-align
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// common styles
.no-scrollbar,
.no-scrollbar > .os-padding > .os-viewport {
  overflow: hidden !important;
}

.no-transitions * {
  transition: none !important;
}

.visually-hidden {
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}
