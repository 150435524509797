@use 'src/styles/app' as a;

$error-color: a.$red-300 !default;

$text-color: a.$gray-400 !default;
$text-color-hover: a.$gray-400 !default;
$text-color-focus: a.$gray-400 !default;
$text-color-filled: a.$gray-400 !default;
$text-color-disabled: a.$gray-400 !default;

$border-color: a.$blue !default;
$border-color-hover: a.$blue-dark !default;
$border-color-focus: a.$blue-dark !default;
$border-color-filled: a.$blue !default;
$border-color-disabled: a.$gray-200 !default;

.field {
  $b: &;

  display: flex;
  position: relative;
  margin-bottom: 20px;
  flex-direction: column;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.276;
  perspective: 1000px;

  @include a.breakpoint(xl) {
    font-size: 14px;
    line-height: 1.25;
  }

  &__input {
    color: $text-color;
    height: 52px;
    border: 2px solid $border-color;
    outline: none;
    padding: 12px 20px;
    font-size: inherit;
    background: transparent;
    border-radius: 10px;
    transition-property: color, border, box-shadow;

    @include a.breakpoint(xl) {
      padding: 15px 20px;
    }
    @include a.breakpoint(lg) {
      height: 46px;
      padding: 13px 20px;
    }
    @include a.breakpoint(md) {
      height: 44px;
      padding: 12px;
    }
    @include a.breakpoint(sm) {
      border-width: 1px;
    }

    @include a.hover {
      color: $text-color-hover;
      border-color: $border-color-hover;

      & ~ #{$b}__label {
        color: $text-color-hover;
      }
    }

    &:disabled {
      color: $text-color-disabled;
      border-color: $border-color-disabled;

      & ~ #{$b}__label {
        color: $text-color-disabled;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      & ~ #{$b}__label {
        transform: scale(0.75) translate(2px, -36px);

        @include a.body-bg;
        @include a.breakpoint(lg) {
          transform: scale(0.75) translate(1px, -33px);
        }
      }
    }

    &:focus,
    &:focus:not(:placeholder-shown) {
      color: $text-color-focus;
      border-color: $border-color-focus;

      & ~ #{$b}__label {
        color: $border-color-focus;
      }
    }

    &:not(:placeholder-shown) {
      color: $text-color-filled;
      border-color: $border-color-filled;

      & ~ #{$b}__label {
        color: $border-color-filled;
      }

      &:hover {
        border-color: $border-color-focus;

        & ~ #{$b}__label {
          color: $border-color-focus;
        }
      }

      &:disabled {
        border-color: $border-color-disabled;

        & ~ #{$b}__label {
          color: $border-color-disabled;
        }
      }
    }

    &_text {
      height: 196px;
      padding: 14px 20px;
      font-size: 16px;
      border-radius: 8px;

      @include a.breakpoint(xl) {
        padding: 16px 20px;
      }
      @include a.breakpoint(lg) {
        height: 178px;
        padding: 14px 20px;
      }
      @include a.breakpoint(md) {
        height: 172px;
        padding: 13px 20px;
      }
      @include a.breakpoint(sm) {
        padding: 13px 12px;
      }

      & ~ #{$b}__label {
        font-size: 16px;

        @include a.breakpoint(md) {
          left: 17px;
        }
        @include a.breakpoint(sm) {
          left: 9px;
        }
      }

      &:focus,
      &:not(:placeholder-shown) {
        & ~ #{$b}__label {
          transform: scale(0.75) translate(1px, -36px);

          @include a.breakpoint(xl) {
            transform: scale(0.75) translate(1px, -39px);
          }
          @include a.breakpoint(lg) {
            transform: scale(0.75) translate(1px, -37px);
          }
          @include a.breakpoint(md) {
            transform: scale(0.75) translate(1px, -35px);
          }
        }
      }
    }

    &.invalid {
      border-color: $error-color;

      & ~ #{$b}__label {
        color: $error-color;
      }
    }
  }

  &__label {
    top: 15px;
    left: 17px;
    padding: 0 4px;
    position: absolute;
    background: transparent;
    pointer-events: none;
    transform-origin: 0 100%;
    transition-property: all;

    @include a.breakpoint(xl) {
      top: 17px;
    }
    @include a.breakpoint(lg) {
      top: 15px;
    }
    @include a.breakpoint(md) {
      top: 14px;
      left: 9px;
    }

    &:hover,
    &:focus {
      border-color: $border-color-focus;
    }

    &:disabled {
      color: $text-color-disabled;
      border-color: $border-color-disabled;
    }

    &:not(:placeholder-shown) {
      color: $text-color-filled;
      border-color: $border-color-filled;
    }
  }

  &__error {
    right: 20px;
    color: $error-color;
    bottom: -6px;
    padding: 0 4px;
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;

    @include a.body-bg;
    @include a.breakpoint(md) {
      padding: 0 12px;
    }

    @at-root .invalid ~ & {
      margin-top: 2px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
