@use 'src/styles/app' as a;

.post {
  $b: &;

  --primary: #{a.$pink};
  --primary-dark: #{a.$pink-dark};

  display: flex;
  padding: var(--spacer-40);
  position: relative;
  box-shadow: inset 0 0 0 var(--border-width-5) var(--primary);
  perspective: 1000px;
  border-radius: var(--border-radius-24);
  flex-direction: column;
  text-decoration: none;
  transition-property: color, background;

  &__img {
    overflow: hidden;
    position: relative;
    margin-bottom: var(--spacer-24);
    border-radius: var(--border-radius-16);

    &::after {
      content: '';
      display: block;
      padding-top: calc(100% / (560 / 346));
    }

    &-src {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      transition-property: all;
    }
  }

  &__info {
    margin-bottom: 6px;
  }

  &__title,
  &__subtitle {
    margin: 0;
    transition-property: color;
  }

  &__about {
    margin-top: var(--spacer-24);
  }

  &__table {
    display: table;
    margin-top: var(--spacer-24);
  }

  &__row {
    display: table-row;

    &:first-child > div {
      padding-top: 0;
    }
  }

  &__cell {
    display: table-cell;
    padding-top: 6px;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      padding-left: var(--spacer-10);
    }
  }

  @include a.hover {
    color: #fff;
    background: var(--primary);

    #{$b}__title,
    #{$b}__subtitle,
    .post-meta__name {
      color: #fff;
    }

    #{$b}__img-src {
      transform: scale(1.05);
    }
  }

  &_hot {
    #{$b}__title {
      gap: var(--spacer-10);
      display: flex;
      align-items: center;

      &::before {
        width: 1em;
        height: a.em(30, 32);
        content: '';
        display: block;
        background: {
          size: contain;
          image: url('/src/uploads/assets/icons/hot.svg');
        }
      }
    }
  }
}
