@use 'src/styles/app' as a;

.lift {
  left: 50%;
  width: 100%;
  height: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  max-width: var(--container-lg);
  transform: translateX(-50%);
  align-items: flex-end;
  justify-content: flex-end;

  &__btn {
    color: a.$light-color;
    width: 60px;
    height: 60px;
    border: 2px solid currentColor;
    opacity: 0;
    font-size: 12px;
    transform: translate(100%, 25%);
    background: a.$blue;
    visibility: hidden;
    margin-right: a.$gap * -1;
    margin-bottom: 40px;
    border-radius: var(--border-radius-20);
    transition-property: all;

    @include a.breakpoint(lg) {
      width: 50px;
      height: 50px;
      margin-right: a.$gap-lg * -1;
    }
    @include a.breakpoint(md) {
      margin-right: a.$gap-md * -1;
    }
    @include a.breakpoint(sm) {
      margin-bottom: 30px;
    }
    @include a.breakpoint(xs) {
      transform: translateY(-25%);
      margin-right: var(--gutter);
    }

    &::after {
      content: a.$ico-chevron-up-400;
      display: block;
      line-height: 0;
      font-family: ico, serif;
    }

    @include a.hover {
      background: a.$primary-dark;
    }

    &_show {
      opacity: 1;
      transform: translate(100%, 0);
      visibility: visible;

      @include a.breakpoint(xs) {
        transform: translateY(0);
      }
    }
  }
}
