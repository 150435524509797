@use 'src/styles/app' as a;

.media-block {
  --h3-color: var(--primary);

  gap: var(--spacer-32);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__img {
    display: flex;
    justify-content: center;
  }

  &__cell {
    width: a.col(6, a.$gap);

    @include a.breakpoint(lg) {
      width: a.col(6, a.$gap-lg);
    }

    &:first-child {
      @include a.breakpoint(md) {
        width: 100%;
      }
    }

    &:last-child {
      @include a.breakpoint(md) {
        width: a.col(8, a.$gap-md);
      }
      @include a.breakpoint(sm) {
        width: a.col(3, a.$gap-md, 4);
      }
      @include a.breakpoint(xs) {
        width: 100%;
      }
    }
  }
}
