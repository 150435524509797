@use '../app' as a;

:where(h1, .h1) {
  font-size: var(--font-size-90);
  font-weight: a.$h1-font-weight;
  line-height: a.$h1-line-height;
  margin: {
    top: 0;
    bottom: var(--spacer-24);
  }

  @if (a.$h1-color) {
    color: var(--h1-color, var(--accent, var(--color, #{a.$h1-color})));
  }
}

:where(h2, .h2) {
  font-size: var(--font-size-44);
  font-weight: a.$h2-font-weight;
  line-height: a.$h2-line-height;
  margin-bottom: var(--spacer-24);

  @if (a.$h2-color) {
    color: var(--h2-color, var(--accent, var(--color, #{a.$h2-color})));
  }
}

:where(h3, .h3) {
  font-size: var(--font-size-32);
  font-weight: a.$h3-font-weight;
  line-height: a.$h3-line-height;
  margin-bottom: var(--spacer-xs);

  @if (a.$h3-color) {
    color: var(--h3-color, var(--accent, var(--color, #{a.$h3-color})));
  }

  a {
    text-decoration: {
      color: currentColor;
      thickness: 2px;
    }

    &:hover {
      color: currentColor;
      text-decoration-color: transparent;
    }
  }
}

:where(h4, .h4) {
  font-size: var(--font-size-22);
  font-weight: a.$h4-font-weight;
  line-height: a.$h4-line-height;
  margin-bottom: var(--spacer-xs);

  @if (a.$h4-color) {
    color: var(--h4-color, var(--accent, var(--color, #{a.$h4-color})));
  }

  &:first-child {
    margin-top: 0;
  }
}

:where(h1, .h1, h2, .h2, h3, .h3, h4, .h4) {
  margin-top: var(--spacer-80);
  font-family: a.$body-font-family;
  margin-bottom: 0;

  :where(a) {
    text-decoration: {
      color: transparent;
    }
  }

  & + :where(p, ol, ul, h3, h4, h5, .figure) {
    margin-top: var(--spacer-16);
  }

  &:where(:first-child) {
    margin-top: 0;
  }

  &:where(:last-child) {
    margin-bottom: 0;
  }
}
