@use 'src/styles/app' as a;
@use 'feature';

.features {
  $b: &;

  gap: 80px;
  display: grid;

  &_row {
    gap: 98px;
    display: flex;

    @include a.breakpoint(xl) {
      gap: 84px;
    }
    @include a.breakpoint(lg) {
      gap: 42px;
    }
    @include a.breakpoint(md) {
      gap: 32px 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include a.breakpoint(xs) {
      margin: 0 auto;
      max-width: 240px;
      grid-template-columns: 1fr;
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include a.breakpoint(xl) {
      gap: 64px 63px;
    }
    @include a.breakpoint(lg) {
      gap: 40px 120px;
      grid-template-columns: repeat(3, 1fr);
    }
    @include a.breakpoint(md) {
      gap: 40px 60px;
    }
    @include a.breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include a.breakpoint(xs) {
      gap: 32px 20px;
    }
    @include a.breakpoint(md) {
      gap: 32px 20px;
    }
  }
}
