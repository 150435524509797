@use 'src/styles/app' as a;
@use 'card';

.cards {
  $b: &;

  gap: var(--spacer-32);
  display: flex;
  flex-wrap: wrap;
  counter-reset: index;

  &_list {
    gap: var(--spacer-60);
    flex-direction: column;
  }

  &_solid {
    color: a.$gray-100;
    padding: var(--padding-48);
    background: var(--primary);
  }

  &_section {
    align-items: flex-start;
    border-radius: var(--border-radius-24);
  }

  &_modules {
    gap: var(--spacer-60) var(--spacer-32);
  }
}
