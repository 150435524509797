@use 'src/styles/app' as a;

.partners {
  $b: &;

  gap: 52px;
  display: flex;
  position: relative;
  justify-content: center;

  @include a.breakpoint(xl) {
    gap: 26px;
  }
  @include a.breakpoint(lg) {
    gap: 40px;
    flex-wrap: wrap;
    max-width: 838px;
  }
  @include a.breakpoint(md) {
    gap: 44px;
    max-width: 610px;
  }
  @include a.breakpoint(sm) {
    gap: 70px;
  }
  @include a.breakpoint(xs) {
    gap: 28px;
  }

  &__item {
    --primary: #{a.$blue};

    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;

    @include a.breakpoint(lg) {
      width: 180px;
    }
    @include a.breakpoint(md) {
      width: 120px;
    }
    @include a.breakpoint(xs) {
      width: 150px;
    }

    &:hover {
      #{$b}__thumb {
        box-shadow: 0 0 0 2px var(--primary);
        border-radius: 26px;

        @include a.breakpoint(lg) {
          border-radius: 22px;
        }
        @include a.breakpoint(md) {
          border-radius: 18px;
        }
        @include a.breakpoint(lg) {
          border-radius: 12px;
        }
      }
    }
  }

  &__thumb {
    width: 100%;
    display: flex;
    overflow: hidden;
    background: var(--primary);
    box-shadow: 0 0 0 var(--primary);
    flex-shrink: 0;
    border-radius: 24px;
    justify-content: center;
    transition-property: box-shadow, border-radius;

    @include a.breakpoint(lg) {
      border-radius: 20px;
    }
    @include a.breakpoint(md) {
      border-radius: 16px;
    }
    @include a.breakpoint(lg) {
      border-radius: 10px;
    }
  }

  &__img {
    width: auto;
    height: 100%;
  }

  &__about {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: currentColor;
    margin: var(--spacer-10) 0 0;

    &::after {
      width: 200px;
      height: 2px;
      margin: 2px auto 0;
      content: '';
      display: block;
      background: var(--primary);
      border-radius: 4px;
      transition-property: transform;

      @include a.breakpoint(xl) {
        width: 120px;
      }
      @include a.breakpoint(lg) {
        width: 80px;
      }
    }
  }

  &__text {
    margin-top: var(--spacer-10);
  }

  &_about {
    gap: 60px 112px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include a.breakpoint(lg) {
      gap: 40px 60px;
    }
    @include a.breakpoint(md) {
      gap: 40px;
      grid-template-columns: 1fr;
    }

    #{$b}__item {
      width: 100%;
      text-align: left;
      align-items: flex-start;

      @include a.breakpoint(md) {
        gap: 24px;
        flex-direction: row;
      }
      @include a.breakpoint(sm) {
        gap: 20px;
      }
      @include a.breakpoint(xs) {
        gap: 10px;
        flex-direction: column;
      }
    }

    #{$b}__name {
      margin-top: var(--spacer-24);

      @include a.breakpoint(md) {
        margin: 0;
      }

      &::after {
        margin-left: 0;
      }
    }

    #{$b}__thumb {
      height: 160px;

      @include a.breakpoint(xl) {
        height: 134px;
      }
      @include a.breakpoint(lg) {
        height: 108px;
      }
      @include a.breakpoint(md) {
        width: auto;
        height: 80px;
      }
      @include a.breakpoint(sm) {
        height: 62px;
      }
    }
  }
}
