@use 'sass:map';
@use 'sass:list';
@use 'sass:meta';
@use '../variables' as v;

@mixin join($rule, $values...) {
  $result: ();

  @each $value in $values {
    @if ($value != null) {
      $result: list.append($result, $value, 'comma');
    }
  }

  @if (length($result) > 0) {
    #{$rule}: $result;
  }
}

@mixin varify-simple-map($map) {
  @each $key, $value in $map {
    @include breakpoint($key) {
      @content ($value);
    }
  }
}

@mixin varify-strong-map($map) {
  @each $name, $props in $map {
    @each $key, $value in $props {
      @include breakpoint($key) {
        @content ($name, $value);
      }
    }
  }
}

@mixin is-retina {
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    @content;
  }
}

@mixin breakpoint($size, $type: 'down') {
  @if (meta.type-of($size) == string) {
    $size: map.get(v.$breakpoints, $size);

    @if ($size != null) {
      @if ($type == 'down') {
        @media (max-width: $size) {
          @content;
        }
      } @else {
        @media (min-width: $size + 1) {
          @content;
        }
      }
    } @else {
      @content;
    }
  } @else {
    @if ($type == 'down') {
      @media (max-width: $size) {
        @content;
      }
    } @else {
      @media (min-width: $size + 1) {
        @content;
      }
    }
  }
}

@mixin breakpoint-between($min, $max) {
  $min: map.get(v.$breakpoints, $min) + 1;
  $max: map.get(v.$breakpoints, $max);

  @if ($min != null and $max != null) {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin apply-styles($map) {
  @each $size, $props in $map {
    @include breakpoint($size) {
      @each $name, $value in $props {
        #{$name}: $value;
      }
    }
  }
}

@mixin hover() {
  @media (hover) {
    &:hover {
      @content;
    }
  }
}

@mixin visually-hidden() {
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}
