@use 'src/styles/app' as a;

.hero {
  $b: &;

  width: 100%;
  margin: 80px auto var(--spacer-200);
  max-width: var(--container-lg);
  text-align: center;
  padding-inline: var(--gutter);

  @include a.breakpoint(lg) {
    margin-top: 40px;
  }
  @include a.breakpoint(md) {
    margin-top: 20px;
  }
  @include a.breakpoint(xs) {
    margin-top: 0;
  }

  &__slogan {
    width: 100%;
    margin: 0 auto 32px;
    display: block;
    max-width: 409px;

    @include a.breakpoint(xl) {
      max-width: 387px;
    }
    @include a.breakpoint(lg) {
      max-width: 324px;
      margin-bottom: 24px;
    }
    @include a.breakpoint(md) {
      max-width: 274px;
    }
    @include a.breakpoint(sm) {
      max-width: 240px;
    }
  }

  &__title {
    margin: 0 auto;
    max-width: a.em(1280, 90);

    [data-word='vision-based'] {
      position: relative;
      perspective: 1px;

      &::after {
        left: 0;
        right: 0;
        bottom: a.em(19, 90);
        height: 5px;
        content: '';
        z-index: -1;
        position: absolute;
        background: a.$yellow;
        border-radius: 4px;
        transform-origin: 0 0;
        transition: {
          delay: a.$time * 1.5;
          property: opacity, transform;
        }

        @include a.breakpoint(lg) {
          height: 4px;
        }
        @include a.breakpoint(md) {
          height: 3px;
        }
      }

      &[data-scroll] {
        &::after {
          opacity: 0;
          transform: scaleX(0.05);
          transition-delay: 0s;
        }
      }
    }

    [data-word='automation'] {
      color: transparent;
      position: relative;
      transition: {
        delay: a.$time * 2.5;
        property: background;
      }
      background: {
        clip: text;
        size: 200% 100%;
        image: linear-gradient(
          90deg,
          a.$blue,
          a.$blue 50%,
          a.$dark-color 0,
          a.$dark-color
        );
        repeat: no-repeat;
        position: 0 100%;
      }

      &[data-scroll] {
        transition-delay: 0s;
        transition-duration: 0s;
        background-position-x: 100%;
      }
    }
  }
}
