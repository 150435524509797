$root-font-size: 18 !default;

$body-font-family: 'Lato', sans-serif !default;
$mono-font-family: 'JetBrains Mono', mono !default;

$font-sizes: (
  90: (
    init: 90px,
    xl: 70px,
    lg: 50px,
    md: 44px,
    sm: 36px,
  ),
  44: (
    init: 44px,
    lg: 32px,
    md: 26px,
    sm: 24px,
  ),
  32: (
    init: 32px,
    xl: 26px,
    lg: 20px,
    md: 18px,
  ),
  22: (
    init: 22px,
    xl: 18px,
    lg: 16px,
  ),
  18: (
    init: 18px,
    lg: 14px,
  ),
) !default;

$line-heights: (
  init: 1.6,
  lg: 1.4,
) !default;

$body-font-size: $root-font-size + px !default;
$body-font-weight: 400 !default;

$h1-font-weight: 700 !default;
$h1-line-height: 1.2 !default;

$h2-font-weight: 700 !default;
$h2-line-height: 1.3 !default;

$h3-font-weight: 700 !default;
$h3-line-height: 1.3 !default;

$h4-font-weight: 700 !default;
$h4-line-height: 1.3 !default;
