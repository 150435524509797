@use 'src/styles/app' as a;

.card {
  $b: &;

  width: a.col(6, a.$gap);
  display: flex;
  position: relative;
  perspective: 1000px;
  margin-left: auto;
  border-radius: var(--border-radius-24);
  flex-direction: column;

  @include a.breakpoint(lg) {
    width: a.col(6, a.$gap-lg);
  }
  @include a.breakpoint(md) {
    width: a.col(6, a.$gap-md);
  }
  @include a.breakpoint(sm) {
    width: 100%;
  }

  &__img {
    display: flex;
    margin-bottom: var(--spacer-24);

    &_emoji {
      width: 100px;

      @include a.breakpoint(lg) {
        width: 80px;
      }
      @include a.breakpoint(md) {
        width: 60px;
      }
    }

    &_emojis {
      width: 340px;
      margin-inline: auto;

      @include a.breakpoint(xl) {
        width: 280px;
      }
      @include a.breakpoint(lg) {
        width: 220px;
      }
    }
  }

  &__title {
    margin: 0 0 var(--spacer-10);
  }

  &__about {
    color: var(--color);

    &:first-child {
      margin-top: 0;
    }
  }

  &:hover {
    &::before {
      transform: scale(0.988);
    }
  }

  &_wide {
    width: 100%;
  }

  &_solid {
    --color: #{a.$light-color};

    border: var(--border-width-5) solid transparent;
    padding: var(--padding-48);
    background: var(--primary);
  }

  &_outline {
    border: var(--border-width-5) solid var(--primary);
    padding: var(--padding-48);
  }

  &_counter {
    gap: var(--spacer-32);
    width: 100%;
    flex-direction: row;

    @include a.breakpoint(md) {
      gap: 16px;
      flex-direction: column;
    }

    #{$b}__title {
      gap: var(--spacer-32);
      width: a.col(4.5);
      display: flex;
      align-items: center;

      @include a.breakpoint(md) {
        width: 100%;
      }

      &::before {
        color: var(--primary);
        width: 80px;
        height: 80px;
        display: flex;
        content: counter(index);
        font-size: 40px;
        background: a.$light-color;
        flex-shrink: 0;
        line-height: 1;
        align-items: center;
        border-radius: var(--border-radius-16);
        justify-content: center;
        counter-increment: index;

        @include a.breakpoint(lg) {
          width: 60px;
          height: 60px;
          font-size: 32px;
        }
        @include a.breakpoint(md) {
          width: 40px;
          height: 40px;
          font-size: 24px;
        }
      }
    }

    #{$b}__about {
      width: a.col(7.5);

      @include a.breakpoint(md) {
        width: 100%;
      }
    }
  }

  &_text-center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &_list {
    width: 100%;

    @include a.breakpoint(xl) {
      width: a.col(6, a.$gap);
    }
    @include a.breakpoint(md) {
      width: 100%;
    }

    ul {
      display: flex;
      justify-content: space-between;

      @include a.breakpoint(xl) {
        flex-direction: column;
      }
      @include a.breakpoint(md) {
        flex-direction: row;
      }
      @include a.breakpoint(sm) {
        flex-direction: column;
      }
    }

    li {
      @include a.breakpoint(xl, up) {
        margin-top: 0;
      }
      @include a.breakpoint-between(sm, md) {
        margin-top: 0;
      }
    }
  }

  &_text {
    --color: var(--primary, currentColor);

    color: var(--color);
    align-self: flex-end;
  }

  &_footer {
    @include a.breakpoint(md) {
      width: a.col(4, a.$gap-md, 6);
    }
    @include a.breakpoint(sm) {
      width: a.col(3, a.$gap-md, 4);
    }
    @include a.breakpoint(xs) {
      width: 100%;
    }
  }

  &_font-up {
    font-size: 22px;

    @include a.breakpoint(xl) {
      font-size: inherit;
    }
  }
}
