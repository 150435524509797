*,
::after,
::before {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:disabled {
  pointer-events: none;
}

:where(:root) {
  tab-size: 2;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:where(video) {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

img {
  display: block;
  object-fit: cover;
  user-select: none;
}

figure {
  margin: 0;
}

picture {
  img {
    margin: 0;
    border-radius: inherit;
  }
}

:where(button) {
  cursor: pointer;
}

:where(textarea) {
  resize: none;
}

:where(input, button, select, textarea) {
  font: inherit;
  color: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
  letter-spacing: inherit;
  background-color: transparent;
}

::placeholder {
  transition: color 0.15s ease-out;
}

:focus {
  &::placeholder {
    color: transparent;
  }
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

:where(body) {
  margin: 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */

:where(dl, ol, ul) {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */

:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

:where(abbr[title]) {
  text-decoration: underline dotted;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

:where(small) {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

:where(iframe) {
  border: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color in Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: currentColor; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

:where(button, input, select) {
  margin: 0;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

:where(button, [type='button' i], [type='reset' i], [type='submit' i]) {
  -webkit-appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

:where(progress) {
  vertical-align: baseline;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

:where([type='search' i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

:where([aria-busy='true' i]) {
  cursor: progress;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

:where([aria-disabled='true' i], [disabled]) {
  cursor: not-allowed;
}

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

:where(img) {
  height: auto;
  max-width: 100%;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

:where([aria-hidden='false' i][hidden]) {
  display: initial;
}

:where([aria-hidden='false' i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(select) {
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E")
    no-repeat right center / 1em;
  padding-right: 1em;
}

/**
 * Don't show the arrow for multiple choice selects
 */

:where(select[multiple]) {
  background-image: none;
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */

:where([type='color' i], [type='range' i]) {
  border-width: 0;
  padding: 0;
}
