@use 'src/styles/app' as a;
@use '../field/field';

.feedback {
  gap: 32px;
  display: flex;
  padding: var(--padding-48);
  flex-wrap: wrap;
  box-shadow: inset 0 0 0 var(--border-width-5) a.$blue;
  border-radius: 24px;
  justify-content: space-between;

  @include a.breakpoint(lg) {
    gap: 24px;
  }

  &__group {
    flex: 1;
  }

  &__submit {
    width: 100%;
    margin-top: 0;
  }
}
