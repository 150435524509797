@use 'src/styles/app' as a;

.about-case {
  $b: &;

  gap: var(--spacer-32);
  display: flex;
  flex-wrap: wrap;

  &__text {
    width: a.col(4, a.$gap);

    @include a.breakpoint(lg) {
      width: a.col(4, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: a.col(8, a.$gap-md);
    }
    @include a.breakpoint(sm) {
      width: 100%;
    }
  }

  &__card {
    --color: #{a.$light-color};

    color: var(--color);
    width: a.col(8, a.$gap);
    border: var(--border-width-5) solid var(--primary);
    padding: var(--padding-48);
    background: var(--primary);
    border-radius: var(--border-radius-24);

    @include a.breakpoint(lg) {
      width: a.col(8, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: 100%;
    }

    ul {
      gap: 12px 24px;
      display: grid;
      grid-template-columns: repeat(3, auto);

      @include a.breakpoint(xl) {
        grid-template-columns: repeat(2, auto);
      }
    }

    li {
      margin: 0;
    }
  }
}
