@use 'src/styles/app' as a;

.navbar {
  $b: &;

  z-index: 100;
  position: relative;
  user-select: none;

  @include a.breakpoint(md) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
    position: fixed;
    visibility: hidden;
    transition-property: opacity, visibility;

    @include a.body-bg;
  }

  &__inner {
    @include a.breakpoint(md) {
      top: 112px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      position: absolute;
    }
  }

  &__menu {
    display: flex;

    @include a.breakpoint(md) {
      margin: 0 auto;
      max-width: var(--container-lg);
      flex-direction: column;
      padding-inline: var(--gutter);
    }
  }

  &__item {
    display: flex;
    position: relative;
    perspective: 1000px;
    margin-left: 64px;
    flex-direction: column;

    @include a.breakpoint(xl) {
      margin-left: 48px;
    }
    @include a.breakpoint(lg) {
      margin-left: 24px;
    }
    @include a.breakpoint(md) {
      margin: {
        top: 16px;
        left: 0;
      }
    }

    &:first-child {
      margin: {
        top: 0;
        left: 0;
      }
    }
  }

  &__link {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition-property: color;

    @include a.breakpoint(md) {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.3;
    }

    @include a.hover {
      color: a.$primary;
    }

    &:not(:last-child) {
      &::after {
        content: a.$ico-chevron-down-500;
        font-size: 6px;
        line-height: 0;
        font-weight: 400;
        margin-left: 6px;
        font-family: ico, serif;
        transition-property: transform;
      }
    }

    &_active {
      color: a.$primary;
    }

    #{$b}__item:hover {
      #{$b}__link {
        &::after {
          transform: rotateX(180deg);
        }
      }
    }

    #{$b}__item_open {
      &::after {
        @include a.breakpoint(md) {
          transform: rotateX(180deg);
        }
      }
    }
  }

  &__dropdown {
    top: 100%;
    right: 0;
    border: 2px solid a.$blue;
    opacity: 0;
    z-index: 10;
    overflow: hidden;
    position: absolute;
    transform: translateY(12px);
    visibility: hidden;
    will-change: transform;
    border-radius: 16px;
    transform-origin: 0 0;
    transition-property: all;

    @include a.body-bg;
    @include a.breakpoint(md) {
      left: 0;
      width: 100%;
      border: none;
      opacity: 1;
      padding: 0;
      display: none;
      position: relative;
      transform: none;
      visibility: visible;
    }

    #{$b}__item:hover & {
      opacity: 1;
      transform: none;
      visibility: visible;
      transition-delay: 0.05s;

      @include a.breakpoint(md) {
        transform: none;
        transition-delay: 0s;
      }
    }
  }

  &_open {
    @include a.breakpoint(md) {
      opacity: 1;
      visibility: visible;
    }
  }
}
