@use '../app' as a;

@font-face {
  font-family: 'ico';
  src: url('/src/assets/fonts/icons.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato-italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato-bold-italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato-black.woff2') format('woff2');
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/src/assets/fonts/lato-black-italic.woff2') format('woff2');
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@if (a.$font-preset == 'article') {
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('/src/assets/fonts/JetBrainsMono.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }
}
