@use 'src/styles/app' as a;

.js-wow {
  transform: scale(1.025) translateY(60px);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: {
    delay: calc(var(--index) * 0.12s);
    duration: a.$time * 3;
    property: clip-path, transform;
    timing-function: a.$ease-out-sine;
  }

  &[data-scroll='in'] {
    transform: none;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
