@use 'src/styles/app' as a;

.team {
  $b: &;

  &__title {
    margin: var(--spacer-80) 0 var(--spacer-32);
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  &__group {
    gap: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include a.breakpoint(xl) {
      gap: 63px;
    }
    @include a.breakpoint(lg) {
      gap: 24px;
    }
    @include a.breakpoint(md) {
      gap: 29px;
    }
    @include a.breakpoint(sm) {
      gap: 60px;
    }
    @include a.breakpoint(sm) {
      gap: 20px;
    }
  }

  &__item {
    width: 268px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;

    @include a.breakpoint(xl) {
      width: 220px;
    }
    @include a.breakpoint(lg) {
      width: 190px;
    }
    @include a.breakpoint(xs) {
      width: 154px;
    }

    &::after {
      width: 200px;
      height: 2px;
      content: '';
      display: block;
      background: var(--primary);
      margin-top: 2px;
      border-radius: 3px;

      @include a.breakpoint(xl) {
        width: 120px;
      }
      @include a.breakpoint(lg) {
        width: 190px;
      }
      @include a.breakpoint(md) {
        width: 80px;
      }
    }

    &:hover {
      #{$b}__ico {
        background: var(--primary);
      }
    }
  }

  &__ico {
    width: 180px;
    display: flex;
    margin-bottom: var(--spacer-10);
    border-radius: var(--border-radius-32);
    transition-property: background;

    @include a.breakpoint(lg) {
      width: 160px;
    }
    @include a.breakpoint(md) {
      width: 120px;
    }
  }

  &__name {
    margin: 0;
  }

  &__role {
    color: a.$gray-400;
  }

  &__foot {
    gap: var(--spacer-32);
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--spacer-80);
    align-items: center;
    justify-content: center;

    .btn {
      margin: 0;
    }
  }
}
