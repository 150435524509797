@use '../app' as a;

.alert {
  .tingle-modal-box {
    color: #000;
    max-width: 420px;
    text-align: center;

    h3 {
      color: #000;
      margin: 0 0 1rem;
      font-size: 32px;
      font-weight: 600;
    }

    .btn {
      margin-top: 1rem;
    }
  }
}
