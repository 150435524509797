@use 'src/styles/app' as a;

.media-section {
  $b: &;

  color: a.$light-color;
  margin: 0 auto var(--spacer-200);
  padding: 140px var(--gutter);
  background: a.$dark-color;

  @include a.breakpoint(xl) {
    padding-block: 120px;
  }
  @include a.breakpoint(lg) {
    padding-block: 80px;
  }
  @include a.breakpoint(md) {
    padding-block: 60px;
  }

  &__inner {
    gap: var(--spacer-32);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: var(--container-lg);
    align-items: center;
  }

  &__title {
    color: a.$blue;

    &::after {
      width: 100%;

      @include a.breakpoint(lg) {
        width: 50%;
      }
    }
  }

  &__col {
    width: a.col(6, a.$gap);

    @include a.breakpoint(lg) {
      width: 100%;
    }

    &:last-child {
      display: flex;
      justify-content: center;

      @include a.breakpoint(lg) {
        margin-top: 40px;
      }
    }
  }
}
