@use 'src/styles/app' as a;

.stage {
  display: flex;
  flex-direction: column;

  &__title {
  }

  &__img {
    margin: var(--spacer-24) 0;
    display: flex;
    background: var(--primary);
    border-radius: var(--border-radius-24);
    justify-content: center;
  }

  &_about {
  }
}
