.tingle-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: url("data:image/svg+xml,%3Csvg width='19' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.514.535l-6.42 6.42L2.677.536a1.517 1.517 0 00-2.14 0 1.517 1.517 0 000 2.14l6.42 6.419-6.42 6.419a1.517 1.517 0 000 2.14 1.517 1.517 0 002.14 0l6.419-6.42 6.419 6.42a1.517 1.517 0 002.14 0 1.517 1.517 0 000-2.14l-6.42-6.42 6.42-6.418a1.517 1.517 0 000-2.14 1.516 1.516 0 00-2.14 0z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E"),
    auto;
  opacity: 0;
  z-index: 1500;
  display: flex;
  padding: var(--gutter);
  overflow: auto;
  position: fixed;
  visibility: hidden;
  background: rgba(#000, 0.9);
  align-items: center;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

@supports (
  (-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))
) {
  .tingle-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
}

/* confirm and alerts
-------------------------------------------------------------- */

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

/* modal
-------------------------------------------------------------- */

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  top: 20px;
  right: 20px;
  color: #fff;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  padding: 0;
  position: fixed;
  background-color: transparent;
}

.tingle-modal__close svg * {
  fill: currentColor;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  max-width: var(--container-md);

  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  opacity: 1;
  cursor: auto;
  will-change: transform, opacity;
}

.tingle-modal-box__content {
  padding: 1.5rem;
}

.tingle-modal-box__footer {
  padding: 1.5rem 2rem;
  width: auto;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f5f5f5;
  cursor: auto;
}

.tingle-modal-box__footer::after {
  display: table;
  clear: both;
  content: '';
}

.tingle-modal-box__footer--sticky {
  position: fixed;
  bottom: -200px; /* TODO : find a better way */
  z-index: 10001;
  opacity: 1;
  transition: bottom 0.3s ease-in-out 0.3s;
}

/* state
-------------------------------------------------------------- */

.tingle-enabled {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  animation: scale 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

/* animations
-------------------------------------------------------------- */

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
