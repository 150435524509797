@use '../app' as a;

html {
  height: 100%;
}

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}

.page {
  $b: &;

  color: a.$body-color;
  display: flex;
  min-height: 100vh;
  line-height: var(--line-height);
  flex-direction: column;
  font: {
    size: var(--font-size-18);
    weight: a.$body-font-weight;
    family: a.$body-font-family;
  }

  @include a.body-bg;

  &__main {
    flex: 1 0 auto;
  }

  &__preloader {
    top: 0;
    left: 50%;
    width: 100vw;
    bottom: 0;
    opacity: 0;
    z-index: 1500;
    display: flex;
    position: fixed;
    overflow: hidden;
    transform: translateX(-50%);
    visibility: hidden;
    background: rgba(a.$gray-500, 0.985);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(12px);
    transition: {
      delay: a.$time;
      duration: a.$time;
      property: opacity, visibility;
    }
  }

  &_loading {
    #{$b}__main,
    #{$b}__header,
    #{$b}__footer {
      * {
        transition: none !important;
      }
    }

    #{$b}__preloader {
      opacity: 1;
      visibility: visible;
    }
  }
}

.preloader {
  --uib-size: 260px;
  --uib-speed: 1.2s;
  --uib-color: #{a.$yellow-200};
  --uib-line-weight: 6px;

  width: var(--uib-size);
  height: var(--uib-line-weight);
  display: flex;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  align-items: center;
  border-radius: calc(var(--uib-line-weight) / 2);
  justify-content: center;

  &::after,
  &::before {
    width: 100%;
    height: 100%;
    content: '';
  }
  &::before {
    top: 0;
    left: 0;
    opacity: 0.1;
    position: absolute;
    background-color: var(--uib-color);
  }

  &::after {
    opacity: 0;
    animation: raceBy var(--uib-speed) ease-in-out infinite;
    transform: translateX(-100%);
    border-radius: calc(var(--uib-line-weight) / 2);
    background-color: var(--uib-color);
  }
}

@keyframes raceBy {
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
