@use 'src/styles/app' as a;
@use 'stage';

.stages {
  gap: var(--spacer-32);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include a.breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include a.breakpoint(sm) {
    gap: 24px;
    grid-template-columns: 1fr;
  }
}
