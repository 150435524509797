@use 'src/styles/app' as a;

.section {
  $b: &;

  margin: 0 auto var(--spacer-200);
  padding: 0 var(--gutter);

  &__inner {
    margin: 0 auto;
    max-width: var(--container-lg);
  }

  &__title {
    margin: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__cell {
    width: a.col(6, a.$gap);
    margin-top: var(--spacer-60);

    @include a.breakpoint(lg) {
      width: a.col(6, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: a.col(4, a.$gap-md, 6);
    }
    @include a.breakpoint(sm) {
      width: a.col(3, a.$gap-md, 4);
    }
    @include a.breakpoint(xs) {
      width: 100%;
    }

    &_lg {
      width: a.col(7, a.$gap);

      @include a.breakpoint(lg) {
        width: a.col(7, a.$gap-lg);
      }
      @include a.breakpoint(md) {
        width: a.col(4, a.$gap-md, 6);
      }
      @include a.breakpoint(sm) {
        width: a.col(3, a.$gap-md, 4);
      }
      @include a.breakpoint(xs) {
        width: 100%;
      }
    }

    &_left {
      &:first-child {
        margin-top: var(--spacer-16);
      }
    }

    &_right {
      margin-left: auto;
    }

    &_footer {
      --color: a.$yellow;

      color: a.$yellow;
      margin-left: auto;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__body {
    gap: var(--spacer-120);
    margin: var(--spacer-60) 0;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_dark {
    background: a.$dark-color;
    padding-block: 100px 140px;

    @include a.breakpoint(lg) {
      padding-block: 80px 100px;
    }
    @include a.breakpoint(md) {
      padding-block: 60px 80px;
    }

    :where(h1, h2, h3, h4) {
      color: a.$light-color;
    }
  }
}
