@use 'src/styles/app' as a;

.digits {
  $b: &;

  gap: var(--spacer-32);
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: a.col(4, a.$gap);
    text-align: center;

    @include a.breakpoint(lg) {
      width: a.col(4, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: a.col(4, a.$gap-md);
    }
    @include a.breakpoint(xs) {
      width: 100%;
    }
  }

  &__num {
    color: var(--primary);
    font-size: 90px;
    font-weight: 900;
    line-height: 1;

    @include a.breakpoint(xl) {
      font-size: 80px;
    }
    @include a.breakpoint(lg) {
      font-size: 60px;
    }
    @include a.breakpoint(md) {
      font-size: 40px;
    }

    .char {
      transition: transform 0.3s cubic-bezier(0.3, 0, 0.3, 1),
        opacity 0.3s linear;
      transition-delay: calc(20ms + (30ms * var(--char-index)));

      [data-scroll='out'] & {
        opacity: 0.4;
        transform: translateX(calc(0.2em * var(--char-index)));
      }
    }
  }

  &__about {
    color: currentColor;
  }
}
