$ico-logo: '\e804';
$ico-mill: '\e805';

$ico-share-fb: '\e806';
$ico-share-in: '\e807';
$ico-share-tw: '\e809';
$ico-share-link: '\e808';

$ico-chevron-up-400: '\e802';
$ico-chevron-up-500: '\e803';

$ico-chevron-down-400: '\e800';
$ico-chevron-down-500: '\e801';
