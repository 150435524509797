@use 'colors' as *;

$primary: $blue !default;
$primary-dark: $blue-dark !default;

$body-bg: #fff !default;
$body-color: $gray-400 !default;

$font-preset: 'base' !default;

$scrollbar-color: $yellow !default;
$scrollbar-active-color: $yellow-dark !default;

// CONTENTS
$h1-color: $gray-500 !default;
$h2-color: $gray-500 !default;
$h3-color: $gray-500 !default;
$h4-color: $gray-500 !default;
$h5-color: $gray-500 !default;

@mixin body-bg {
  background: {
    size: 1920px 1080px;
    color: #fff;
    image: url('/src/uploads/assets/bg.png');
    repeat: repeat;
    position: 50% 0;
  }
}
