@use 'src/styles/app' as a;

.post-meta {
  $b: &;

  &__author {
    display: flex;
    margin-top: 4px;
  }

  &__name {
    color: a.$dark-color;
    font-weight: 700;
    transition-property: color;
  }

  &_card {
    #{$b}__author {
      flex-direction: column;
    }

    #{$b}__role {
      font-size: 14px;

      @include a.breakpoint(lg) {
        font-size: 12px;
      }
      @include a.breakpoint(md) {
        font-size: 10px;
      }
    }
  }

  &_article {
    #{$b}__author {
      gap: 10px;
    }
  }
}
