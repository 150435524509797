@use 'src/styles/app' as a;

.scheme {
  text-align: center;

  &__body {
    padding: var(--padding-48);
    background: a.$gray-400;
    margin-top: var(--spacer-24);
    border-radius: var(--border-radius-24);
  }

  &__title {
    color: a.$light-color;
    margin-bottom: var(--spacer-24);
  }

  &__list {
    gap: 11px;
    display: grid;

    @include a.breakpoint(sm) {
      gap: 6px;
    }
  }

  &__group {
    gap: 11px;
    display: grid;

    @include a.breakpoint(sm) {
      gap: 6px;
    }

    &_head {
      grid-template-columns: repeat(4, 1fr);

      @include a.breakpoint(xs) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &_foot {
      grid-template-columns: repeat(6, 1fr);

      @include a.breakpoint(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include a.breakpoint(xs) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__item {
    color: a.$gray-400;
    margin: 0;
    display: flex;
    padding: 14px 12px;
    background: a.$gray-100;
    font-weight: 700;
    align-items: center;
    border-radius: 6px;
    align-content: center;
    justify-content: center;

    @include a.breakpoint(md) {
      padding: 10px;
    }

    &_wide {
      color: a.$gray-100;
      background: a.$blue;
    }
  }
}
