@use 'src/styles/app' as a;

.benefits {
  $b: &;

  &__about {
    color: a.$light-color;
    width: a.col(8, a.$gap);

    @include a.breakpoint(xl) {
      width: a.col(9, a.$gap);
    }
    @include a.breakpoint(lg) {
      width: a.col(9, a.$gap-lg);
    }
    @include a.breakpoint(md) {
      width: 100%;
    }

    :where(h2, h3, h4) {
      color: currentColor;
    }
  }

  &__body {
    display: flex;
    padding: 50px 80px;
    flex-wrap: wrap;
    margin-top: 40px;
    background: a.$light-color;
    align-items: center;
    border-radius: var(--border-radius-24);
    justify-content: space-between;

    @include a.breakpoint(xl) {
      padding: 50px;
    }
    @include a.breakpoint(lg) {
      padding: 30px;
    }
    @include a.breakpoint(md) {
      padding: 24px 30px;
      margin-top: 32px;
      align-items: flex-start;
    }
    @include a.breakpoint(sm) {
      padding: 24px;
    }
    @include a.breakpoint(xs) {
      flex-direction: column;
    }
  }

  &__list {
    gap: 20px;
    width: 360px;
    margin: 0;
    display: flex;
    flex-direction: column;

    @include a.breakpoint(xl) {
      width: 300px;
    }
    @include a.breakpoint(lg) {
      width: 260px;
    }
    @include a.breakpoint(md) {
      gap: 12px;
      order: 1;
      width: 230px;
    }
    @include a.breakpoint(sm) {
      width: 200px;
    }
    @include a.breakpoint(xs) {
      margin: 0 auto 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    color: a.$dark-color;
    position: relative;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
    padding-left: 12px;

    @include a.breakpoint(xl) {
      font-size: 18px;
    }
    @include a.breakpoint(lg) {
      font-size: 16px;
      padding-left: 8px;
    }
    @include a.breakpoint(md) {
      font-size: 14px;
    }
    @include a.breakpoint(sm) {
      font-size: 12px;
    }

    &::after {
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      content: '';
      position: absolute;
      background: a.$red;
      border-radius: 2px;
    }
  }

  &__robot {
    display: block;

    @include a.breakpoint(md) {
      order: 0;
      width: 100%;
      margin-bottom: 24px;
    }

    &-src {
      width: 260px;
      margin: 0 auto;

      @include a.breakpoint(xl) {
        width: 240px;
      }
      @include a.breakpoint(lg) {
        width: 220px;
      }
      @include a.breakpoint(md) {
        width: 140px;
      }
    }
  }

  &_dark {
    #{$b}__about {
      color: a.$gray-400;

      :where(h2, h3, h4) {
        color: a.$gray-500;
      }
    }

    #{$b}__body {
      background: a.$red;
    }

    #{$b}__item {
      color: a.$gray-100;

      &::after {
        background: currentColor;
      }
    }
  }
}
