@use 'src/styles/app' as a;

.collage {
  $b: &;

  gap: var(--spacer-32);
  display: flex;
  flex-wrap: wrap;

  &__group {
    gap: var(--spacer-32);
    display: flex;
    flex-wrap: wrap;

    &:nth-child(1) {
      width: a.col(4, a.$gap);

      @include a.breakpoint(lg) {
        width: a.col(4, a.$gap-lg);
      }
      @include a.breakpoint(md) {
        width: a.col(5, a.$gap-md);
      }
      @include a.breakpoint(xs) {
        width: 100%;

        #{$b}__item {
          width: a.col(6, a.$gap-md);
        }
      }
    }

    &:nth-child(2) {
      width: a.col(5, a.$gap);

      @include a.breakpoint(lg) {
        width: a.col(5, a.$gap-lg);
      }
      @include a.breakpoint(md) {
        width: a.col(7, a.$gap-md);
      }
      @include a.breakpoint(xs) {
        width: 100%;
      }
    }

    &:nth-child(3) {
      width: a.col(3, a.$gap);

      @include a.breakpoint(lg) {
        width: a.col(3, a.$gap-lg);
      }
      @include a.breakpoint(md) {
        width: 100%;

        #{$b}__item {
          width: a.col(4, a.$gap-md);

          @include a.breakpoint(xs) {
            width: a.col(6, a.$gap-md);
          }

          &:nth-child(3n) {
            @include a.breakpoint(xs) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    border-radius: var(--border-radius-24);
  }
}
