@use 'src/styles/app' as a;

.tabs-menu {
  --primary: #{a.$blue};
  --primary-dark: #{a.$blue-dark};

  gap: 12px;
  display: flex;
  flex-wrap: wrap;

  &__link {
    color: a.$gray-400;
    border: 1px solid var(--primary);
    outline: none;
    padding: 8px 10px;
    font-size: 16px;
    line-height: 1.4;
    border-radius: 10px;
    transition-property: color, border, background, box-shadow;

    @include a.breakpoint(lg) {
      font-size: 14px;
    }
    @include a.breakpoint(md) {
      font-size: 11px;
    }
    @include a.breakpoint(sm) {
      font-size: 10px;
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--primary);
    }

    @include a.hover {
      color: a.$light-color;
      background: var(--primary-dark);
      box-shadow: 0 0 0 var(--primary);
      border-color: var(--primary-dark);
    }

    &:active {
      color: a.$gray-500;
      background: var(--primary);
      box-shadow: 0 0 0 var(--primary);
      border-color: var(--primary);
    }

    &_active {
      color: a.$light-color;
      background: var(--primary);
      pointer-events: none;

      &:focus {
        box-shadow: 0 0 0 var(--primary);
      }
    }
  }
}
